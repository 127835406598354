import React from "react";
import Header from "../components/Header";
import Helmet from "react-helmet";
import "../styles/om_oss.scss";

export default function om_oss() {
  return (
    <div className="om__oss__container">
      <div className="om__oss__wrapper">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ljungs Måleri & Byggnadsvård</title>
        </Helmet>

        <article className="om_oss__wrapper">
          <p>
            Ljungs måleri startade 2021 men har trots det över 20 år erfarenhet
            inom branschen. Vi har gjort det mesta inom måleri och jobbat i en
            hel del olika projekt relaterande byggnadsvård.
          </p>
          <p>Vi är baserade utanför Landskrona men jobbar i hela Skåne.</p>
          <p>
            Företagets vision är att aldrig lämna en kund missnöjd och att
            utföra alla våra jobb på högsta möjliga nivå.
          </p>
        </article>
      </div>
    </div>
  );
}
